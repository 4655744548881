import React from "react";
import wabobaLogo from "assets/waboba-logo.png";
import wabobaLogoWhite from "assets/waboba-logo-white.png";

const BizLogo = ({ dark, height = 24 }) => (
  <div className="flex items-center justify-center py-3">
    <img src={dark ? wabobaLogoWhite : wabobaLogo} alt="Waboba" style={{ height }} />
    <span className=" translate-y-1 ml-1" style={{ fontSize: 16 }}>
      Business
    </span>
  </div>
);

export default BizLogo;
