import React from "react";
import styled from "styled-components";
import ItemSize from "../../components/ItemSize";

const Cell = styled.div`
  padding: 0 10px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  height: 40px;
  line-height: 40px;
`;

function getHsCode(productLine) {
  if (!productLine) return "";
  return `${productLine.hsCode} ${productLine.hsCodeForEu ? `EU: ${productLine.hsCodeForEu}` : ""} ${
    productLine.hsCodeForUs ? `US: ${productLine.hsCodeForUs}` : ""
  }`;
}

function getMaterials(productLine) {
  if (productLine && productLine.children) {
    if (productLine.children.length > 0) {
      return productLine.children.map((i) => `- ${i.name}: ${i.materials.map((j) => j.material.name).join(", ")}`).join("\n");
    }
    return productLine.materials.map((i) => i.material.name).join(", ");
  }
  return " - ";
}

function getPanTone(productLine) {
  if (!productLine) return "";
  if (productLine.children.length > 0) {
    return productLine.children.map((i) => `- ${i.name}: ${i.pantone}`).join("\n");
  }
  return productLine.pantone;
}

const ProductData = ({ items, productLine }) => {
  return (
    <div className="flex relative border">
      <div className="aboslute border-r font-bold">
        <Cell>Item</Cell>
        <Cell>Item Number</Cell>
        <Cell>Product Weight</Cell>
        <Cell>Product Size</Cell>
        <Cell>HS Code</Cell>
        <Cell>Product Materials</Cell>
        <Cell>Pantone</Cell>
        <Cell>MOQ</Cell>
        <Cell>Packing</Cell>
        <Cell>Inner Box/Clam Shel Size</Cell>
        <Cell>Barcode EAN</Cell>
        <Cell>Barcode UPC</Cell>
        <Cell>Quantity per Displaybox</Cell>
        <Cell>Display Box Size</Cell>
        <Cell>Inner Carton Size</Cell>
        <Cell>Outer Carton Size</Cell>
        <Cell>CTN Net Weight</Cell>
        <Cell>CTN Gross Weight</Cell>
        <Cell>Outer Carton CMB</Cell>
        <Cell>Quantity per Carton</Cell>
      </div>
      <div className="flex overflow-auto flex-1 text-center">
        {items.map((i, index) => (
          <div
            className="flex-1"
            key={i.id}
            style={{
              backgroundColor: index % 2 === 0 ? "#fafafa" : "white",
            }}
          >
            <Cell>{i.name}</Cell>
            <Cell>{i.number}</Cell>
            <Cell>{i.itemWeight} g</Cell>
            <Cell>
              <ItemSize {...i} />
            </Cell>
            <Cell>{getHsCode(productLine)}</Cell>
            <Cell>{getMaterials(productLine)}</Cell>
            <Cell>{getPanTone(productLine)}</Cell>
            <Cell>{i.moq}</Cell>
            <Cell>{i.packing}</Cell>
            <Cell>
              <ItemSize shape="cube" cubeX={i.innerBoxX} cubeY={i.innerBoxY} cubeZ={i.innerBoxZ} unit="mm" />
            </Cell>
            <Cell>
              Inner Box: {i.innerBoxBarcodeEan || " - "} Outer Carton: {i.outerCartonBarcodeEan || " - "}
            </Cell>
            <Cell>
              Inner Box: {i.innerBoxBarcodeUpc || " - "} Outer Carton: {i.outerCartonBarcodeUpc || " - "}
            </Cell>
            <Cell>{i.quantityPerDisplayBox}</Cell>
            <Cell>
              <ItemSize shape="cube" cubeX={i.displayBoxX} cubeY={i.displayBoxY} cubeZ={i.displayBoxZ} unit="mm" />
            </Cell>
            <Cell>
              <ItemSize shape="cube" cubeX={i.innerCartonX} cubeY={i.innerCartonY} cubeZ={i.innerCartonZ} unit="cm" />
            </Cell>
            <Cell>
              <ItemSize shape="cube" cubeX={i.outerCartonX} cubeY={i.outerCartonY} cubeZ={i.outerCartonZ} unit="cm" />
            </Cell>
            <Cell>{i.ctnNetWeight} kg</Cell>
            <Cell>{i.ctnGrossWeight} kg</Cell>
            <Cell>{i.outerCartonCbm} m³</Cell>
            <Cell>{i.quantityPerCarton}</Cell>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductData;
