import React from "react";
import { useQuery, gql } from "@apollo/client";
import { Button } from "components/shared/base";
import Spinner from "components/shared/Spinner";
import Errors from "components/shared/Errors";
import http from "utils/http";
import PageTitle from "components/PageTitle";

const FETCH_LOGOS = gql`
   query FETCH_ALL_LOGOS($collectionName: String) {
      logos(collectionName: $collectionName) {
         id
         name
         thumb: url(size: "300x300")
         url
         originFile
         active
      }
   }
`;

const Logos = () => {
   const { loading, error, data } = useQuery(FETCH_LOGOS, {
      variables: { collectionName: "2023 Logos" }
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   return (
      <div>
         <PageTitle title="Waboba Logos" />

         <div className="flex flex-wrap items-baseline justify-center my-6">
            {data.logos.map((logo, index) => (
               <div
                  key={index}
                  className="mx-6 mb-6 flex flex-col items-center"
               >
                  <div
                     style={{ backgroundImage: "url(" + logo.thumb + ")" }}
                     className={`mb-4 w-48 h-48 bg-contain bg-center bg-no-repeat
                     ${
                        process.env.NODE_ENV === "development"
                           ? " bg-gray-100"
                           : ""
                     }`}
                  />

                  <div className="text-center font-bold text-gray-600">
                     {logo.name}
                  </div>

                  <Button
                     title="download"
                     link={logo.originFile}
                     target="_blank"
                     rel="noopener noreferrer"
                     onClick={_ => {
                        http.post("/an/", {
                           name: "download promo image",
                           content: logo.url
                        });
                     }}
                  />
               </div>
            ))}
         </div>
      </div>
   );
};

export default Logos;
